import React, { useState } from "react"
import { Container, Row } from "react-bootstrap"
import Seo from "../components/seo"
import SideBar from "../components/sideBar/SideBar"
import ProvHeroSection from "../components/banners/providerPage"
import BenefitsSection from "../sections/provider-section/ProvBenefits"
import WhyNowSection from "../sections/provider-section/whyNow"
import Pos from "../sections/provider-section/pos"
import WhyUsSection from "../sections/provider-section/WhyUs"
import Partners from "../components/partners/Partners"
import ProviderNav from "../components/homeNav/ProviderNav"
import FaqSection from "../sections/faq/Faq"
import Footer from "../components/homeNav/footer/Footer"
import { data, PharmMgtSys } from "../sections/provider-section/providerData"

export default function ProviderIndex() {
  const [isOpen, serIsOpen] = useState(false)
  const toggle = () => {
    serIsOpen(!isOpen)
  }
  return (
    <Container fluid style={{ overflow: "hidden" }}>
      <Seo
        title="Online platform and tools for pharmacies | Afyabook Pro Kenya"
        description="Make a difference in your patients lives with the tools you need to provide medication, medication therapy management and patient monitoring"
        img="https://www.afyabook.com/static/8f4339bfc7163c1851f593c247d64895/39112/knowledge-thumb.webp"
        keywords="point of sale kenya, POS, pharmacy management system kenya, software for pharmacy"
        siteUrl="https://www.afyabook.com/providers"
        canonical="https://www.afyabook.com/providers"
      ></Seo>
      <Row>
        <SideBar isOpen={isOpen} toggle={toggle} />
        <ProviderNav toggle={toggle} />
        <ProvHeroSection />
        <BenefitsSection />
        <WhyNowSection data={data} />
        <WhyUsSection />
        <Pos data={PharmMgtSys} />
        <FaqSection />
        <Partners />
        <Footer />
      </Row>
    </Container>
  )
}
