import React, { useState } from "react"
import axios from "axios"
import { Modal } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import {
  TextWrapper,
  Heading,
  Subtitle,
  ImgCont,
  BannerBtn,
  ProvInfoContainer,
  ProvInfoWrapper,
} from "../../infoSection/infoSectionElements"
import {
  HeaderWrapper,
  ModalHeader,
  StyledButton,
  StyledForm,
  StyledIcon,
  StyledInput,
  SuccessContainer,
  SuccessHeader,
  SuccessImg,
  SuccessSubtitle,
  SuccessText,
} from "../../providerSection/providerElements"
import { AnimatePresence } from "framer-motion"
import HeroSuccess from "../thankyou.svg"
import useAuthState from "../../../stores/auth"
export default function Provider() {
  const { user } = useAuthState()
  const [modalOpen, setModalOpen] = useState(false)
  const [show, setShow] = useState(false)

  //success modal state
  const close = () => setModalOpen(false)
  const open = () => setModalOpen(true)

  const handleClose = () => {
    setShow(false)
    setSuccess("")
    setError("")
  }
  const handleShow = () => setShow(true)

  const [name, setName] = useState(
    user?.firstName
      ? user.firstName
      : "" + "" + user?.lastName
      ? user.lastName
      : ""
  )
  const [email, setEmail] = useState(user?.email || "")

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const uploadData = async () => {
    try {
      setError("")
      setSuccess("")
      if (!name || !email) {
        setError("Missing required fields")
      } else {
        setLoading(true)

        const data = {
          name,
          email,
          type: "provider Pharmacy",
          //change type for each page
        }
        await axios.post(`${process.env.GATSBY_API_URL}/registrations/`, {
          ...data,
        })
        //uncomment later on

        setSuccess("Success")
        setLoading(false)
        setSubmitted(true)
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: EditProduct.js ~ line 54 ~ editProduct ~ error",
        err
      )
      setLoading(false)
      const { message } = err.response.data
      setError(message)
    }
  }
  const text = "Successfully added!"

  return (
    <ProvInfoContainer>
      <ProvInfoWrapper>
        <TextWrapper>
          <Heading>
            Increase revenue while cutting costs and saving time
          </Heading>
          <Subtitle>
            Providing retail pharmacies with the tools they need to increase
            revenue while lowering costs
          </Subtitle>

          <BannerBtn onClick={handleShow}>Get started</BannerBtn>
          <Modal
            show={show}
            onHide={handleShow}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <StyledForm>
              <AnimatePresence
                initial={false}
                exitBeforeEnter={true}
                onExitComplete={() => null}
              >
                {success && (
                  <>
                    <SuccessContainer>
                      <StyledIcon onClick={handleClose} />
                      <SuccessImg src={HeroSuccess} />
                      <SuccessHeader>Welcome onboard, Dr.{name}</SuccessHeader>
                      <SuccessText>Thank you for joining</SuccessText>
                      <SuccessSubtitle>Afyabook for Providers</SuccessSubtitle>
                    </SuccessContainer>
                  </>
                )}
              </AnimatePresence>
              {!success && (
                <>
                  <StyledIcon onClick={handleClose} />
                  <HeaderWrapper>
                    {/* <ModalImg src={BannerBg} /> */}
                    <ModalHeader>
                      Increase revenue while cutting costs and saving time
                    </ModalHeader>
                    <Subtitle>
                      Providing retail pharmacies with the tools they need to
                      increase revenue while lowering costs
                    </Subtitle>
                  </HeaderWrapper>
                  <label htmlFor="name">Name</label>
                  <StyledInput
                    type="text"
                    name="name"
                    value={name}
                    onChange={e => {
                      setName(e.target.value)
                    }}
                  />
                  <label htmlFor="email">Email</label>
                  <StyledInput
                    type="email"
                    name="email"
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value)
                    }}
                  />
                  {loading && (
                    <p style={{ color: "green", fontSize: "1.2rem" }}>
                      Loading...
                    </p>
                  )}
                  <StyledButton
                    disabled={submitted || loading || !name || !email}
                    onClick={() =>
                      uploadData() && (modalOpen ? close() : open())
                    }
                  >
                    Send Message
                  </StyledButton>{" "}
                </>
              )}
            </StyledForm>
          </Modal>
        </TextWrapper>
        <ImgCont>
          {/* <HeroImg src={BannerBg} /> */}
          <StaticImage
            height={600}
            src="../../../images/knowledge-thumb.webp"
            alt="Provider digital tools"
            placeholder="blurred"
          />
        </ImgCont>
      </ProvInfoWrapper>
    </ProvInfoContainer>
  )
}
